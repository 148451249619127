<template>
  <div class="page">
    <div class="qc_code5">
      <div class="top_box">
        <div class="headInfo">
          <div class="logo">
            <img :src="defaultData.logo" alt="" />
          </div>
          <div class="title">
            <div class="shop_name">{{ shopInfo.name }}</div>
            <div class="shop_describe">{{defaultData.describe}}</div>
          </div>
        </div>
      </div>
      <div class="bottom_box">
        <div class="bottom">
          <div class="code">
            <img :src="shopInfo.employeeQrcode" style="width: 179px;height: 179px;display: block" alt="" />
          </div>
        </div>
        <div class="address">{{shopInfo.address}}</div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    props: {
      defaultData: Object,  //传过来的数据进行定义
      shopInfo: Object,
    },
    data() {
      return {

      }
    },
    created() {

    },
    mounted() {

    },
    methods: {

    }

  }
</script>

<style lang="less" scoped>
  .qc_code5 {
    padding: 0 10px;
    margin-top: 15px;

    .top_box {
      background-image: url("../../../static/images/backStyle5.png");
      background-size: cover;
      margin: 0 10px;

      .headInfo {
        padding: 38px 24px 25px 24px;
        display: flex;
        flex-direction: column;

        .title {
          .shop_name {
            margin-top: 30px;
            font-size: 17px;
            font-weight: 400;
            color: #4E4E4E;
          }

          .shop_describe {
            margin-top: 5px;
            font-size: 20px;
            font-weight: 500;
            color: #4E4E4E;
          }
        }

        .logo {
          img {
            width: 42px;
            height: 42px;
            border-radius: 50%;
          }
        }
      }

    }

    .bottom_box {
      padding: 55px 35px 35px 35px;
      margin: 0 10px;
      background: #FFFFFF;
      border-radius: 0 0 10px 10px;

      .bottom {
        display: flex;
        justify-content: center;
      }

      .address {
        margin-top: 35px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        text-align: center;
      }
    }
  }
</style>