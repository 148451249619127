<template>
    <div class="page">
        <div class="qc_code2">
            <div class="title">{{defaultData.guide}}</div>
            <div class="bottom">
              <div class="code">
                <img :src="shopInfo.employeeQrcode"
                  style="width: 179px;height: 179px;display: block" alt="" />
              </div>
            </div>
            <div class="top">
              <div class="right">
                <div class="shop_name">{{ shopInfo.name }}</div>
                <div class="shop_describe">{{defaultData.describe}}</div>
              </div>
            </div>
            <div class="address">
              {{shopInfo.address}}
            </div>
          </div>
          <div class="logo_box">
            <img
            :src="defaultData.logo"
              alt="" />
          </div>
    </div>
  </template>
  
  <script>
    export default {
      props: {
      defaultData: Object,  //传过来的数据进行定义
      shopInfo:Object,
    },
      data() {
        return {

        }
      },
      created() {

      },
      mounted() {

      },
      methods: {
     
      }
  
    }
  </script>
  
  <style lang="less" scoped>
.qc_code2 {
    padding: 16px 10px;
    margin: 0 16px;
    background-image: url("../../../static/images/styleBack2.png");
    background-size: cover;

    .title {
      font-size: 29px;
      font-weight: 500;
      color: #FFFFFF;
      text-align: center;
      margin-top: 35px;
    }

    .bottom {
      margin-top: 25px;
      display: flex;
      justify-content: center;
    }

    .top {
      display: flex;
      align-items: center;
      padding: 15px 0 20px 28px;
      width: 179px;
      margin: 0 auto;

      .right {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }

    .shop_name {
      font-size: 17px;
      font-weight: 400;
      color: #FFFFFF;
      text-align: right;
    }

    .shop_describe {
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      text-align: right;
    }

    .address {
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      padding: 0 20px;
      text-align: center;
    }
  }

  .logo_box {
    padding: 16px 10px;
    background: #FFFFFF;
    margin: 0 26px;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: center;

    img {

      width: 34px;
      height: 34px;
      border-radius: 50%;
    }
  }

  </style>