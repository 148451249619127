<template>
    <div class="page">
        <div class="qc_code3">
            <div class="top_box">
              {{defaultData.guide}}
            </div>
            <div class="logo">
              <img
              :src="defaultData.logo" 
                alt="" />
            </div>
            <div class="bottom_box">
              <div class="title">
                <div class="shop_name">{{ shopInfo.name }}</div>
                <div class="shop_describe">{{defaultData.describe}}</div>
              </div>
              <div class="bottom">
                <div class="code">
                  <img :src="shopInfo.employeeQrcode"
                    style="width: 179px;height: 179px;display: block" alt="" />
                </div>
              </div>
              <div class="address">
                {{shopInfo.address}}
              </div>
            </div>
          </div>
    </div>
  </template>
  
  <script>
    export default {
      props: {
      defaultData: Object,  //传过来的数据进行定义
      shopInfo:Object,
      },
      data() {
        return {

        }
      },
      created() {

      },
      mounted() {

      },
      methods: {
     
      }
  
    }
  </script>
  
  <style lang="less" scoped>
 .qc_code3 {
    position: relative;
    margin-top: 15px;
    .top_box {
      background: #3975C6;
      box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
      padding: 45px 0;
      margin: 0 30px;
      font-size: 29px;
      font-weight: 500;
      color: #FFFFFF;
      text-align: center;
    }

    .logo {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin-top: -22px;

      img {
        width: 42px;
        height: 42px;
        border-radius: 50%;
      }

    }

    .bottom_box {
      margin: 0 20px;
      margin-top: -8px;
      background-image: url("../../../static/images/backStyle3.png");
      background-size: cover;
      padding-top: 45px;

      .title {
        .shop_name {
          font-size: 17px;
          font-weight: 400;
          color: #999999;
          display: flex;
          justify-content: center;
        }

        .shop_describe {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          display: flex;
          justify-content: center;
        }
      }

      .bottom {
        margin-top: 15px;
        display: flex;
        justify-content: center;
      }

      .address {
        padding: 0 35px;
        margin-top: 35px;
        padding-bottom: 30px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        text-align: center;
      }

    }
  }

  </style>