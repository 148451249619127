<template>
  <div class="page">
    <div class="qc_code1">
      <div class="top">
        <div class="left">
          <img
            :src="defaultData.logo"
            alt="">
        </div>
        <div class="right">
          <div class="shop_name">{{ shopInfo.name }}</div>
          <div class="shop_describe">{{defaultData.describe}}</div>
        </div>
      </div>
      <div class="bottom">
        <div class="code">
          <img :src="shopInfo.employeeQrcode"
            style="width: 230px;height: 230px;display: block" alt="" />
        </div>
      </div>
      <div class="shop_guide">{{defaultData.guide}}</div>
      <div class="line"></div>
      <div class="address">
        <div class="address_left">
          <img src="@/static/images/locatian.png">
        </div>
        <span>{{shopInfo.address}}</span>
      </div>
      <div class="scissors">
        <div class="scissors_left"></div>
        <div class="scissors_right"></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      defaultData: Object,  //传过来的数据进行定义
      shopInfo:Object,
    },
    data() {
      return {

      }
    },
    created() {

    },
    mounted() {

    },
    methods: {

    }

  }
</script>

<style lang="less" scoped>
  .qc_code1 {
    padding: 0px 10px;
    background: #FFFFFF;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
    margin: 0 16px;
    border-radius: 4px;
    margin-top: 15px;
    .top {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 35px 66px 20px 66px;

      .left {
        margin-right: 9px;

        img {
          width: 34px;
          height: 34px;
          border-radius: 50%;
        }
      }
    }

    .shop_name {
      font-size: 17px;
      color: #333333;
      font-weight: 400;
    }

    .shop_describe {
      font-size: 14px;
      font-weight: 400;
      color: #9B9B9B;
    }

    .shop_guide {
      margin-top: 12px;
      text-align: center;
      color: #818181;
    }

    .bottom {
      display: flex;
      justify-content: center;
    }

    .line {
      margin-top: 20px;
      width: 100%;
      height: 1px;
      border-bottom: 1px dashed #979797;
    }

    .address {
      width: 100%;
      margin-top: 18px;
      display: inline-flex;
      justify-content: center;
      .address_left {
        width: 9px;
        height: 100%;

        img {
          width: 9px;
          height: 11px;
        }
      }

      span {
        font-size: 14px;
        font-weight: 400;
        color: #9B9B9B;
        margin-left: 4px;
        margin-bottom: 10px;
      }
    }

    .scissors {
      width: 100%;
      height: 25px;
      display: flex;
      background: #EBECEF;
      flex-direction: row;

      .scissors_left {
        width: 50%;
        height: 100%;
        background: #FFFFFF;
        -webkit-clip-path: polygon(0 0, 100% 0, 0 100%, 0 0);
      }

      .scissors_right {
        width: 50%;
        height: 100%;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
        background: #FFFFFF;
      }
    }
  }
</style>